.viewfinder {
    height: 100vh;
}

.current-project {
    padding: 0em 1em;
    display: flex;
    justify-content: space-around;
    
}

.in-progress {
    display: flex;
    flex-direction: column;
}

.current-text {
    display: flex;
    align-items: center;
    width: 40%;
}

#current-title {
    text-align: center;
    text-decoration: underline;
    padding-top: 1em;
}

@media (min-width:360px) and (max-width: 800px) {
    .current-project {
        padding: 0em 0.5em;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
    }
    
    .current-text {
        display: flex;
        flex-direction: column;
        font-size: 12px;
        width: fit-content;
        
    }

    
    
    #current-title {
        text-align: center;
        text-decoration: underline;
        padding-top: 2em;
    }

    #cproject {
        border-radius: 6px;
        border: solid 2px ;
        border-color:#c4b5fd;
        width: 160px;
        height: 240px;
        margin: auto;
    }
   

    .cproject {
        height: 400px;
        width: fit-content;
    }
}

@media (min-width: 801px) and (max-width: 1519px) {
   

    .current-project {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        gap: 1em;
        
    }

    .current-text {
        width: 750px;
    }

    #cproject {
        border-radius: 6px;
        border: solid 2px ;
        border-color:#c4b5fd;
        width: 300px;
        height: 405px;
        margin: auto;
    }

   

    .cproject {
        margin: auto;
    }
}

@media (min-width: 1520px) {
    .projectexample {
        height: 400px;
        width: 720px;
        border-radius: 12px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    
    #cproject {
        border-radius: 6px;
        border: solid 2px ;
        border-color:#c4b5fd;
    }

    #cproject:hover {
        scale: 105%;
        transition: all 0.5s ease-in;
    }
}