html {
    scroll-snap-type: y mandatory;
    scroll-padding: 8rem;
}

html a{
    text-decoration: none;
    font-weight: 600;
    overflow: none;
    
}

html a:hover{
    text-decoration: underline;
}

html a:visited{
    text-decoration: none;
}

.App {
    display: flex;
    flex-direction: column;
    justify-content: center;
    
}

.progress-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 8px;
    background: grey;
    transform-origin: 0%;
    z-index: 10;
  }

.toolbar {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 8;
    
}

.topic {
    scroll-snap-align: start;
    height: 100vh;
}

@media (min-width:360px) and (max-width: 800px) {

    html {
        scroll-snap-type: y mandatory;
        scroll-padding: 6rem;
    }

}