@media (min-width: 801px) {
    .toolbar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 1em;
        background-color: #c4b5fd;
        
    }
    
    .toolbar h1 {
        color: black;
        padding: 5px;
        font-size: 50px;
        margin-left: 0.2em;
    
    }

    #cv-button {
        background-color: black;
        color: #AEAEFF;
        font-weight: 800;
        height: 3em;
        width: 10rem;
        border: 0;
        display: inline-block;
        padding: 6px 12px;
        font-size: 18px;
        border-radius: 4px;
        margin-top: 5px;
    }

    #cv-button:hover {
        background-color: ivory;
        color: black;
    }
    
    .contact {
        display: flex;
        flex-direction: column;
        width: 10em;
        margin-right: 1em;
        margin-top: 0.5em;
    }

    .social-contact {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

   
    
}

@media (min-width:360px) and (max-width: 800px) {
    .toolbar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 0.15em;
        background-color: #c4b5fd;
        
    }
    
    .toolbar h1 {
        color: black;
        padding: 3px;
        font-size: 28px;
        margin-left: 0.2em;
    
    }
    
    .contact {
        display: flex;
        flex-direction: column;
        width: 10em;
        margin-right: 1em;
        margin-top: 1.75em;
    }

    .social-contact {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        height: 1em;
        padding-bottom: 0.75em;
    }

    .social-contact img {
        scale: 80%;
    }

    #cv-button {
        background-color: black;
        color: #c4b5fd;
        font-weight: 800;
        height: 2em;
        width: 8rem;
        border: 0;
        display: inline-block;
        margin-left: 14px;
        padding: 6px 12px;
        font-size: 16px;
        border-radius: 4px;
        margin-top: 5px;
        margin-bottom: 5px;
    }
    
    

}