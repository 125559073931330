.viewfinder {
    height: 100vh;
}

h2 {
    text-align: center;
    text-decoration: underline;
    padding-top: 2em;
    font-size: 25px;

}


@media (min-width: 360px) and (max-width: 800px) {
   

    .testiDiv {
        display: grid;
        grid-gap: .5rem;
        grid-template-columns: repeat(auto-fit, minmax(175px, 1fr));   
        margin-top: 0.5em;
        
    }

    .quote {
        /* aspect-ratio: 1; */
        display: grid;
        box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
        padding: 3px;
        border-radius: 12px;
    }

    .quote p{
        font-size: x-small;
    }
}

@media (min-width: 801px) and (max-width: 1519px) {
    .testiDiv {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 5px;
        grid-row-gap: 15px;    
        margin-top: 1em;
        
    }

    .quote {
        box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
        padding: 10px;
        border-radius: 12px;
    }
}

@media (min-width: 1520px) {
    .testiDiv {
        display: flex;
        flex-direction: row;
        
    }

    .quote {
        padding: 20px;
    }
}