.page {
    height: 100vh;
    
}


.page h1 {
    font-size: 25px;
    text-align: center;
    text-decoration: underline;
    padding-top: 1em;
   
}


.project {
    border-radius: 4px;
    border-style: none solid solid solid;
    border-color:  #c4b5fd;
    border-width: 5px;
        
}

.about-project {
    padding: 0 8px;
}

.heading {
    display: flex;
    justify-content: space-between;
    background-color: #c4b5fd;
    color: black;
    padding: 0 0.5em;
    font-size: 24px;
    height: 60px;
}

.smolscreen p {
    font-size: 12px;
}

#project-button {
    border-radius: 4px;
    height: 3rem;
    width: 9rem;
    background-color: #c4b5fd;
    border: 0;
}

#project-button a {
    font-size: 1rem;
    font-weight: 600;
    color: black;
}

@media (min-width: 1520px) {
    
    
    
    .wrap {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        padding: 1em;
        height:min-content;
        margin-top: 0.5em;
    }

    

    .gallery {
        display: flex;
        align-items: center;
        column-gap: 5px;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        overflow-y: hidden;
        margin-bottom: 0.75em;
        height: 40vh;
        padding: 0 8px;
        
        
    }

    .gallery_item {
        scroll-snap-align: center;
        min-width: 32%;
    }

    .gallery_image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .smolscreen {
        visibility: hidden;
    }
    
/* Old CSS here */
}



@media (min-width:360px) and (max-width: 800px) {
    .page {
        overflow: hidden;
        height: 100vh;
    }

    .page h1{
        padding-top: 0.3em;
    }

    .wrap {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 0.2em;
        grid-row-gap: 4px;    
        margin-bottom: 0.5em;
        text-align: center;
        justify-items: center;
    }

    #project-button {
        border-radius: 4px;
        height: 2.5rem;
        width: 8rem;
        background-color: #c4b5fd;
        border: 0;
    }
    
    #project-button a {
        font-size: 0.8rem;
        font-weight: 600;
        color: black;
    }

    .project {
        padding: 0 0em;
        border-style: none solid solid solid;
        border-color:  #c4b5fd;
        border-width: 3px;
    }

    .about-project {
        padding: 0 3px;
        font-size: 12px;
    }
    
    .heading {
        display: flex;
        justify-content: space-between;
        background-color: #c4b5fd;
        height: 45px;
    }

    .heading p {
        color: black;
        font-size: 1rem;
    }

    .current-title {
        padding-top: 0;
    }
    
    

    .gallery {
        display: flex;
        align-items: center;
        column-gap: 10px;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
       
    }

    .gallery_item {
        scroll-snap-align: center;
        min-width: 100%;
    }

    .gallery_image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    .smolscreen {
        visibility: visible;
        text-align: center;
        margin-top: -0.75em;
    }

    
    

}

@media (min-width: 801px) and (max-width: 1519px) {
    .page {
        padding: 1em;
        overflow: hidden;
    }
    
    
    .wrap {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-column-gap: 20px;
        grid-row-gap: 5px;    
        text-align: center;
        padding: 1em;
        justify-items: center;
    }
    
    .project {
        border-radius: 4px;
        border-style: none solid solid solid;
        border-color:  #c4b5fd;
        border-width: 5px;
            
    }
    
    .about-project {
        padding: 0 8px;
    }
    
    .heading {
        display: flex;
        justify-content: space-between;
        background-color: #c4b5fd;
        color: black;
        padding: 0 0.5em;
        font-size: 24px;
        height: 60px;
    }

   
   
    .gallery {
        display: flex;
        align-items: center;
        column-gap: 10px;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        
    }

    .gallery_item {
        scroll-snap-align: center;
        min-width: 75%;
    }

    .gallery_image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
   
    
    .smolscreen {
        visibility: visible;
        text-align: center;
        margin-top: -0.9em;
    }
    
}

