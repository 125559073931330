.intro {
    padding: 2em;
    /* font-size: 18px; */
}

.intro h1 {
    font-size: 40px;
    text-align: center;
}

.project-link {
    display: block;
    text-align: center;
    padding: 1em;
}

.project-link a:hover{
    text-decoration:none;
    
}

.project-link a {
    font-size: 25px;
}

.about {
    display: flex;
    flex-direction: column;
    height: 90vh;
}

.title {
    margin: 0 0.7em;
    /* font-size: 50px; */
    display: flex;
    justify-content: space-evenly;
}
.title img {
    height: 20em;
}

.skills-and-stack {
    background-color: #c4b5fd;
    color: #000000;
    display: flex;
    padding: 0em 0.5em;
    margin-top: 0em;
    border-radius: 4px;
    width: 45%;
    
   
}

.list-title {
    font-weight: 700;
    font-size: 18px;
    
}

.list {
    list-style-type: none;
    margin: 0.5em 0em;
    padding: 0;
}

.list li{
    float: left;
    padding: 0.1em 0.5em;
}

.more-info {
    display: flex;
    justify-content: space-around;
}

.more-info p {
    width: 45%;
}

@media (min-width: 801px) and (max-width: 1519px) {
    .intro {
        padding: 1em;
        /* font-size: 18px; */
    }
    
    .intro h1 {
        font-size: 33px;
        text-align: center;
        
    }
    
    .project-link {
        display: block;
        text-align: center;
        padding: 0;
    }
    
    .project-link a:hover{
        text-decoration:none;
        
    }
    
    .project-link a {
        font-size: 20px;
        ;
    }
    
    .about {
        display: flex;
        flex-direction: column;
        height: 90vh;
    }
    
    .title {
        margin: 0 0.7em;
        /* font-size: 50px; */
        display: flex;
        justify-content: space-evenly;
    }
    .title img {
        height: 16em;
        margin-top: 1em;
    }
    
    .skills-and-stack {
        background-color: #c4b5fd;
        color: #000000;
        display: flex;
        padding: 0em 0.5em;
        margin-top: 0em;
        border-radius: 4px;
        width: 45%;
        
       
    }
    
    .list-title {
        font-weight: 700;
        font-size: 18px;
        
    }
    
    .list {
        list-style-type: none;
        margin: 0.5em 0em;
        padding: 0;
    }
    
    .list li{
        float: left;
        padding: 0.1em 0.5em;
    }
    
    .more-info {
        display: flex;
        justify-content: space-around;
    }
    
    .more-info p {
        width: 45%;
    }

    
}

@media (min-width:360px) and (max-width: 800px) {
    .intro {
        padding: 1.5em;
        /* font-size: 18px; */
    }
    
    .intro h1 {
        font-size: 20px;
        text-align: center;

    }
    
    .project-link {
        display: block;
        text-align: center;
        padding: 0.5em;
    }
    
    .project-link a:hover{
        text-decoration:none;
        
    }
    
    .project-link a {
        font-size: 15px;
    }
    
    .about {
        display: flex;
        flex-direction: column;
        height: 90vh;
    }
    
    .title {
        margin: 0 0.7em;
        /* font-size: 50px; */
        display: flex;
        justify-content: space-evenly;
    }
    .title img {
        height: 10em;
        padding-top: 2em;
    }
    
    .skills-and-stack {
        background-color: #c4b5fd;
        color: #000000;
        display: flex;
        flex-direction: column;
        padding: 0em 0.5em;
        border-radius: 4px;
        width: 40%;
        gap: 1em;
        height: 355px;
    }

    .skills-and-stack div {
        margin-top: 1.25em;
    }
    
    .list-title {
        font-weight: 700;
        font-size: 14px;
        margin: 0.2em 0;
        
    }
    
    .list {
        list-style-type: none;
        margin: 0.5em 0em;
        padding: 0;
    }
    
    .list li{
        /* float: left; */
        padding: 0.1em 0.5em;
        font-size: 12px;
    }
    
    .more-info {
        display: flex;
        justify-content: space-around;
    }
    
    .about-me {
        width: 45%;
        font-size: 14px;
        margin: 0px 5px;
    }

}